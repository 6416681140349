import React from "react";
import CustomIcon from "../MUICustomComponents/CustomIcon";
import { RotateRight } from "@mui/icons-material";
import { PaletteDiscriminatedUnion } from "../theme/foundations/palette";

type Props = {
	color?: PaletteDiscriminatedUnion;
};

export default function LoadingButtonIcon({ color }: Props) {
	return (
		<CustomIcon
			icon={<RotateRight />}
			variant="default"
			isRotating
			{...(color && { color: color })}
		/>
	);
}
